import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <div className="App-content">
        <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="mailto:info@walburge.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@walburge.com
        </a>
        {/* <p className="App-address">
          Walburge LLC
          <br />
          10509 Patterson Ave, #29222
          <br />
          Henrico, VA 23238
        </p> */}
        {/* <p>
          {" "}
          <a
            className="App-link"
            href="https://biblia.com/bible/esv/john/3/16"
            target="_blank"
            rel="noopener noreferrer"
          >
            John 3:16
          </a>
        </p>
        <p>
          {" "}
          <a
            className="App-link"
            href="https://www.armelo.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Armelo
          </a>
        </p>
        <p>
          {" "}
          <a
            className="App-link"
            href="https://www.goodrichhouseent.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Goodrich House
          </a>
        </p>
        <p>
          {" "}
          <a
            className="App-link"
            href="https://www.maisonmickael.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mickaël
          </a>
        </p> */}
      </div>
      <footer className="App-footer">
        <p>&copy; {new Date().getFullYear()} Walburge, LLC.</p>
      </footer>
    </div>
  );
}

export default App;
